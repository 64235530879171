dmx.Actions({

    'bootbox.alert': function(options) {
        var self = this;

        return new Promise(function(resolve) {
            options.callback = resolve;
            bootbox.alert(self.parse(options));
        });
    },

    'bootbox.confirm': function(options) {
        var self = this;

        return new Promise(function(resolve) {
            options = Object.assign({}, options);
            
            var condition = {
                'then': options.then,
                'else': options.else
            };
    
            delete options.then;
            delete options.else;
    
            options.callback = function(result) {
                if (result) {
                    if (condition.then) {
                        return resolve(self._exec(condition.then).then(function() {
                            return result;
                        }));
                    }
                } else if (condition.else) {
                    return resolve(self._exec(condition.else).then(function() {
                        return result;
                    }));
                }

                resolve(result);
            };

            bootbox.confirm(self.parse(options));
        });
    },

    'bootbox.prompt': function(options) {
        var self = this;

        return new Promise(function(resolve, reject) {
            options.callback = function(result) {
                resolve(result);
            };
            bootbox.prompt(self.parse(options));
        });
    }

});